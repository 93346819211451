import React, { createContext, useContext, useState } from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

/**
 * @typedef SnackbarInfo
 * @type {object}
 * @property {string} message - The message to display in the snackbar.
 * @property {"error"|"warning"|"info"|"success"} severity - The severity level of the snackbar message.
 */

/**
 * Context providing the snackbar display function to child components.
 * @type {React.Context<function(message: string, severity: "error"|"warning"|"info"|"success"): void>}
 */
const SnackbarContext = createContext();

/**
 * Hook to provide the `showSnackbar` function from the `SnackbarContext`.
 * @returns {function(string, "error"|"warning"|"info"|"success"): void} Function that allows showing a snackbar with a message and severity.
 */
export const useSnackbar = () => {
  return useContext(SnackbarContext);
};

/**
 * Context provider to allow child components to show a snackbar.
 * @param {object} props
 * @param {React.ReactNode} props.children - The child components of this provider.
 * @returns {React.ReactNode} A provider component with snackbar display capabilities.
 */
export const SnackbarProvider = ({ children }) => {
  /**
   * @type {[SnackbarInfo|null, function(SnackbarInfo|null): void]}
   */
  const [snackbarInfo, setSnackbarInfo] = useState(null);

  /**
   * Display a snackbar with the provided message and severity.
   * @param {string} message - The message to display in the snackbar.
   * @param {"error"|"warning"|"info"|"success"} severity - The severity level of the snackbar message.
   */
  const showSnackbar = (message, severity = "error", close = false) => {
    if (close) {
      setSnackbarInfo(null);
      return;
    }

    setSnackbarInfo({ message, severity });
  };

  /**
   * Close the snackbar if not closed by a clickaway action.
   * @param {React.SyntheticEvent | Event} event - The React `SyntheticEvent` or a DOM `Event`.
   * @param {string} reason - Describes why the snackbar was closed.
   */
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarInfo(null);
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar
        open={snackbarInfo !== null}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        {snackbarInfo && (
          <Alert
            onClose={handleClose}
            severity={snackbarInfo.severity}
            sx={{ width: "100%" }}
          >
            {snackbarInfo.message}
          </Alert>
        )}
      </Snackbar>
    </SnackbarContext.Provider>
  );
};
