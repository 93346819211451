import dayjs from "dayjs";
import _ from "lodash";

/**
 * Checks if a string starts with a number.
 *
 * @param {string} str - The string to check.
 * @returns {boolean} - True if the string starts with a number, false otherwise.
 */
export const startsWithNumber = (str) => /^\d/.test(str);

/**
 * Parses a number from a string.
 *
 * @param {string} str - The string to parse the number from.
 * @returns {number|null} - The parsed number, or null if no number is found.
 */
export const parseNumberFromString = (str) => {
  const match = str.match(/\d+/g);
  return match ? parseInt(match.join(""), 10) : null;
};

export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";
  const cleaned = parseNumberFromString(String(phoneNumber));
  return String(cleaned).replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};

export const formatDate = (
  dateString,
  format = "M/D/YYYY h:mmA",
  defaultReturn = ""
) => {
  return dateString ? dayjs(dateString).format(format) : defaultReturn;
};

/**
 * Formats a nested object by flattening it and adding a prefix to each key.
 *
 * @param {Object} nestedObj - The nested object to be formatted.
 * @param {string|string[]} prefix - The prefix to be added to each key. Can be a string or an array of strings.
 * @returns {Object} - The formatted object with flattened keys and prefixed key names.
 */
export const formatNestedObject = (
  nestedObj,
  prefix = ["prices", "rebates"]
) => {
  if (Array.isArray(prefix)) {
    return prefix.reduce(
      (acc, p) => ({ ...acc, ...formatNestedObject(nestedObj[p], p) }),
      {}
    );
  }

  return Object.entries(nestedObj[0] ?? {}).reduce((acc, [key, value]) => {
    if (value !== null && typeof value === "object" && !Array.isArray(value)) {
      // Recursive call for nested objects
      acc[`${prefix}.${key}`] = formatNestedObject([value], `${prefix}.${key}`);
    } else {
      acc[`${prefix}.${key}`] = value;
    }
    return acc;
  }, {});
};

/**
 * Undo the formatting of a nested object by removing a specified prefix from its keys.
 * If the prefix is an array, it will recursively remove each prefix in the array.
 *
 * @param {Object} flattenedObj - The flattened object to undo the formatting on.
 * @param {string|string[]} prefix - The prefix or array of prefixes to remove from the keys.
 * @returns {Object} - The nested object with the specified prefix(es) removed from its keys.
 */
export const undoFormatNestedObject = (
  flattenedObj,
  prefixes = ["prices", "rebates"]
) => {
  if (!Array.isArray(prefixes)) {
    prefixes = [prefixes];
  }

  const resultObj = { ...flattenedObj };
  const nestedContainers = {};

  prefixes.forEach((prefix) => {
    nestedContainers[prefix] = [{}];
    _.forEach(flattenedObj, (value, key) => {
      if (key.startsWith(prefix + ".")) {
        const nestedKey = key.slice(prefix.length + 1);
        _.set(nestedContainers[prefix][0], nestedKey, value);
        delete resultObj[key]; // Remove the key from the original object
      }
    });
  });

  return { ...resultObj, ...nestedContainers };
};

// A utility format that formats a number as a currency string.
export const currencyFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

/**
 * Formats a currency value by removing non-numeric characters and rounding it to 2 decimal places.
 * If the value is an integer, it will be rounded up to the nearest whole number.
 *
 * @param {Object} params - The parameters for formatting the currency value.
 * @param {string|number} params.value - The value to be formatted.
 * @returns {string} The formatted currency value.
 */
export const formatCurrencyValue = (params) => {
  const numberValue = Number(String(params.value).replace(/[^0-9.]/g, ""));
  if (numberValue % 1 === 0) {
    return Math.ceil(numberValue).toString();
  } else {
    return numberValue.toFixed(2);
  }
};

/**
 * Formats a value as a dollar amount.
 *
 * @param {number} value - The value to format.
 * @returns {string|number} - The formatted dollar amount or the original value if it cannot be formatted.
 */
export const formatAsDollar = (value) => {
  if (!isNaN(value) && typeof value !== "object") {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0, // No decimal places
    }).format(value);
  }
  return value;
};

/**
 * Checks if an array is not empty.
 *
 * @param {Array} arr - The array to check.
 * @returns {boolean} - True if the array is not empty, false otherwise.
 */
export const isArrayWithLength = (arr) => {
  try {
    return Array.isArray(arr) && arr.length;
  } catch (error) {
    console.log("isArrayWithLength: ", error);
    return false;
  }
};

/**
 * Checks if the given value is an object with keys.
 *
 * @param {any} obj - The value to check.
 * @returns {boolean} - Returns true if the value is an object with keys, otherwise false.
 */
export const isObjectWithKeys = (obj) => {
  try {
    return obj !== null && typeof obj === "object" && Object.keys(obj).length;
  } catch (error) {
    console.log("isObjectWithKeys: ", error);
    return false;
  }
};

/**
 * Checks if a value is a function.
 *
 * @param {any} fn - The value to check.
 * @returns {boolean} - Returns true if the value is a function, otherwise returns false.
 */
export const isFunction = (fn) => {
  try {
    return fn !== null && typeof fn === "function";
  } catch (error) {
    console.log("isFunction: ", error);
    return false;
  }
};

/**
 * Recursively trims string values in an object.
 *
 * @param {Object|Array} data - The object or array to trim.
 * @returns {Object|Array} - The new object or array with trimmed string values.
 */
const recursiveTrim = (data) => {
  if (typeof data === "string") {
    return data.trim();
  }

  if (Array.isArray(data)) {
    return data.map((item) => recursiveTrim(item));
  }

  if (_.isObject(data)) {
    return _.mapValues(data, (value) => recursiveTrim(value));
  }

  return data;
};

const recursiveInt = (data) => {
  if (typeof data === "string") {
    return parseInt(data) || 0;
  }

  if (Array.isArray(data)) {
    return data.map((item) => recursiveInt(item));
  }

  if (_.isObject(data)) {
    return _.mapValues(data, (value) => recursiveInt(value));
  }

  return data;
};

/**
 * Checks if two objects are equal.
 *
 * @param {Object} obj1 - The first object to compare.
 * @param {Object} obj2 - The second object to compare.
 * @returns {boolean} - True if the objects are equal, false otherwise.
 */
export const objectsAreEqual = (
  obj1,
  obj2,
  { trim = true, ints = false } = {}
) => {
  const trimmedObj1 = trim ? recursiveTrim(obj1) : obj1;
  const trimmedObj2 = trim ? recursiveTrim(obj2) : obj2;

  if (ints) {
    const intObj1 = recursiveInt(trimmedObj1);
    const intObj2 = recursiveInt(trimmedObj2);
    return _.isEqual(intObj1, intObj2);
  }

  return _.isEqual(trimmedObj1, trimmedObj2);
};

/**
 * Rounds the given value up to the nearest multiple of 5.
 *
 * @param {number} value - The value to be rounded.
 * @returns {number} - The rounded value.
 */
export const handleRoundUp5 = (value) => {
  // Only gets called on de-focus of element
  const valueRound = Math.round(value);

  if (valueRound % 5 === 0) return valueRound;

  // Round the value up
  const updatedValue = Math.round(Math.ceil(valueRound / 5) * 5);
  return updatedValue;
};

/**
 * Calculates the multiplied value of a given number by subtracting an increment.
 * This is a very specific formula used for calculating the price of a product.
 *
 * @param {number} value - The original value.
 * @param {number} increment - The increment to subtract from the value.
 * @returns {number} The multiplied value after subtracting the increment.
 */
export const fsMultiplyValue = (value, increment) => {
  const valueDivide = 1 - +increment;
  const newValue = (Math.ceil(((value / valueDivide) * 2) / 10) * 10) / 2;

  return newValue;
};

/**
 * Formats a string for comparison by converting it to lowercase, removing non-alphanumeric characters, and trimming whitespace.
 * @param {string} str - The string to be formatted.
 * @returns {string} The formatted string.
 */
export const formatStringForComparison = (str) => {
  return String(str)
    .toLowerCase()
    .replace(/[^a-z0-9]/g, "")
    .trim();
};

/**
 * Capitalizes each word in a string separated by a specified character.
 *
 * @param {string} str - The input string.
 * @param {string} [spaceChar='_'] - The character used to separate words.
 * @returns {string} - The capitalized string.
 */
export const capitalizeBySpaceChars = (
  str,
  { spaceChar = "_", addSpaceByCapitals = false, ...rest } = {}
) => {
  let newStr = _.clone(str);

  if (Object.keys(rest).length) {
    console.warn("Invalid options:", rest);
  }

  if (addSpaceByCapitals) {
    newStr = newStr.replace(/([A-Z])/g, " $1");
    // Remove double spaces
    newStr = newStr.replace(/\s{2,}/g, " ");
  }
  return newStr
    .split(spaceChar)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
    .trim();
};

/**
 * Compares two objects or arrays of objects up to 3 levels deep and returns the differences.
 *
 * @param {Object|Object[]} source - The source object or array of objects to compare.
 * @param {Object|Object[]} target - The target object or array of objects to compare against.
 * @param {Object} [options] - Additional options.
 * @param {string} [options.sourceKeyName='original'] - The key name to use for the source value.
 * @param {string} [options.targetKeyName='updated'] - The key name to use for the target value.
 * @returns {Object[]} An array of objects, each representing differences found at each level.
 */
export const deepCompare = (
  source,
  target,
  {
    sourceKeyName = "original",
    targetKeyName = "updated",
    idKey = "product_name",
  } = {}
) => {
  let differences = [];

  // Helper function to handle comparison of individual objects
  const compareObjects = (obj1, obj2, level = 1) => {
    if (level > 3) return;

    // Get unique keys from both objects
    const allKeys = _.union(_.keys(obj1), _.keys(obj2));

    // Iterate over each key and compare values
    allKeys.forEach((key) => {
      if (!_.isEqual(obj1[key], obj2[key])) {
        differences.push({
          level,
          key,
          [idKey]: obj1?.[idKey] || obj2?.[idKey] || "",
          [sourceKeyName]: obj1[key],
          [targetKeyName]: obj2[key],
        });

        // If values are objects, recursively compare
        if (_.isObject(obj1[key]) && _.isObject(obj2[key])) {
          compareObjects(obj1[key], obj2[key], level + 1);
        }
      }
    });
  };

  // If inputs are arrays, compare each pair of objects
  if (Array.isArray(source) && Array.isArray(target)) {
    const maxLength = Math.max(source.length, target.length);
    for (let i = 0; i < maxLength; i++) {
      compareObjects(source[i] || {}, target[i] || {});
    }
  } else if (_.isObject(source) && _.isObject(target)) {
    // If inputs are individual objects, compare them directly
    compareObjects(source, target);
  } else {
    throw new Error(
      "Input should be either two objects or two arrays of objects."
    );
  }

  return differences;
};

/**
 * Formats a number as an accounting string.
 *
 * @param {number} value - The number to format.
 * @param {Object} options - The formatting options.
 * @param {boolean} [options.showSign=false] - Whether to show the sign of the number.
 * @param {number} [options.decimalPlaces=2] - The number of decimal places to display.
 * @returns {string} The formatted accounting string.
 */
export const formatAccountingString = (
  value,
  { showSign = false, decimalPlaces = 2, showNegative = false } = {}
) => {
  const num = parseFloat(value);
  if (isNaN(num)) return ""; // Return an empty string if value is not a number

  const isNegative = num < 0;
  const formattedNumber = Math.abs(num)
    .toFixed(decimalPlaces)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (showNegative && isNegative) {
    return `-${formattedNumber}`;
  }

  return isNegative
    ? `(${showSign ? "$" : ""}${formattedNumber})`
    : formattedNumber;
};

/**
 * Formats a number as a percentage string.
 *
 * @param {number} value - The number to be formatted as a percentage.
 * @param {Object} options - The formatting options.
 * @param {boolean} [options.multiplyBy100=true] - Whether to multiply the value by 100.
 * @param {boolean} [options.showSign=false] - Whether to show the sign (+/-) of the formatted number.
 * @param {number} [options.decimalPlaces=2] - The number of decimal places to include in the formatted number.
 * @returns {string} The formatted percentage string.
 */
export const formatPercentageString = (
  value,
  { multiplyBy100 = true, showSign = false, decimalPlaces = 2 } = {}
) => {
  const num = parseFloat(value);
  if (isNaN(num)) return ""; // Return an empty string if value is not a number

  const formattedNumber = (num * (multiplyBy100 ? 100 : 1)).toFixed(
    decimalPlaces
  );
  return `${formattedNumber}${showSign ? "%" : ""}`;
};

/**
 * Checks if a given string is a valid JSON string.
 *
 * @param {string} str - The string to be checked.
 * @returns {boolean} - Returns true if the string is a valid JSON string, otherwise returns false.
 */
export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Standardizes the given data for Material-UI.
 *
 * @param {Array} data - The data to be standardized.
 * @returns {Array} - The standardized data.
 */
export const standardizeMUIData = (data) => {
  // Validate the data is an array
  if (!Array.isArray(data)) {
    console.error("standardizeMUIData: Data is not an array.");
    return [];
  }

  return data.map((item, index) => ({
    id: index,
    label: capitalizeBySpaceChars(String(item)),
    value: item,
  }));
};

/**
 * Converts an array to an object with keys and values.
 * @param {Array} arr - The array to be converted.
 * @param {string} [defaultValue=null] - The default value to assign to each key.
 * @returns {Object} - The resulting object with keys and values.
 */
export const arrayToObject = (arr, defaultValue = null) => {
  // Validate the data is an array
  if (!Array.isArray(arr)) {
    console.error("arrayToObjectKeys: arr is not an array.");
    return [];
  }

  return arr.reduce((acc, key) => {
    acc[key] = defaultValue;
    return acc;
  }, {});
};

/**
 * Parses a string and returns a formatted name.
 *
 * @param {string} str - The string to be parsed.
 * @returns {string} The formatted name.
 */
export const parseNames = (str) => {
  // Match only valid name characters: letters, hyphens, apostrophes, and spaces
  return str
    .replace(/[^a-zA-Z\s'-]/g, "")
    .trim()
    .replace(/ /g, "-");
};

/**
 * Splits a name into first and last name.
 *
 * @param {string} name - The full name to be split.
 * @returns {Object} An object containing the first name and last name.
 */
export const splitNameToFirstAndLast = (name) => {
  const names = name.split(" ");
  return {
    first: parseNames(names[0]),
    last: parseNames(names.slice(1).join(" ")),
  };
};
