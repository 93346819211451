import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import "react-quill/dist/quill.snow.css";
import App from "./App";
import { LicenseInfo } from "@mui/x-license-pro";
import { SnackbarProvider } from "./hooks/SnackbarProvider";
import { ThemeProvider, createTheme } from "@mui/material";
import { deepmerge } from "@mui/utils";
import { createTheme as createMuiTheme } from "@mui/material/styles"; // This imports the default MUI theme

LicenseInfo.setLicenseKey(
  "8488ef470762adf39eb4d704db84e1cfTz03NzU3NSxFPTE3MzAwNTEzNzMwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

// Create your custom theme and merge it with the default MUI theme
const customTheme = {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      tailwindSidebar: 1024,
    },
  },
  palette: {
    primary: {
      main: "#005aa3",
      secondary: "#db542c",
    },
    secondary: {
      main: "#F87171",
    },
    formForward: {
      main: "#337ab7",
      contrastText: "#fff",
    },
    sidebarCollapse: {
      main: "#a4b3d1",
    },
    neutral: {
      main: "#e0e0e0", // Neutral grey for inactive or unselected buttons
      contrastText: "#000", // Dark text for good contrast
    },
    warning: {
      main: "#ffc107", // Yellow for warning or conditional actions
      contrastText: "#000",
    },
    danger: {
      main: "#d9534f", // Red for danger or destructive actions
      contrastText: "#fff",
    },
    danger1: {
      main: "#f44336", // Red for danger or destructive actions
      contrastText: "#fff",
    },
    danger2: {
      main: "#e57373", // Red for danger or destructive actions
      contrastText: "#fff",
    },
    danger3: {
      main: "#ef9a9a", // Red for danger or destructive actions
      contrastText: "#fff",
    },
    danger4: {
      main: "#ffcdd2", // Red for danger or destructive actions
      contrastText: "#fff",
    },
    info: {
      main: "#2196f3", // Blue for information
      contrastText: "#fff",
    },
    success: {
      main: "#4caf50", // Green for success
      contrastText: "#fff",
    },
    background: {
      default: "#f5f5f5", // Light background to make buttons stand out
    },
  },

  typography: {
    fontFamily: [
      "ui-sans-serif",
      "system-ui",
      "-apple-system",
      "BlinkMacSystemFont",
      "Segoe UI",
      "Roboto",
      "Helvetica Neue",
      "Arial",
      "Noto Sans",
      "sans-serif",
      "Apple Color Emoji",
      "Segoe UI Emoji",
      "Segoe UI Symbol",
      "Noto Color Emoji",
    ].join(","),
  },
};

// Merge with the default MUI theme
const theme = createTheme(deepmerge(createMuiTheme(), customTheme));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <>
    <ThemeProvider theme={theme}>
      <SnackbarProvider>
        <App />
      </SnackbarProvider>
    </ThemeProvider>
  </>
);
