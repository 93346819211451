import React, { createContext, useContext, useState } from "react";

const MuiSidebarContext = createContext();

const useMuiSidebar = () => useContext(MuiSidebarContext);

export const MuiSidebarProvider = ({ children }) => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [showAppbar, setShowAppbar] = useState(false);
  const [appbarComponent, setAppbarComponent] = useState(null);

  const toggleAppbar = (show) => setShowAppbar(show);

  const toggleSidebar = (show = null) => setShowSidebar(show ?? !showSidebar);

  return (
    <MuiSidebarContext.Provider
      value={{
        showAppbar,
        toggleAppbar,
        setAppbarComponent,
        appbarComponent,
        toggleSidebar,
        showSidebar,
      }}
    >
      {children}
    </MuiSidebarContext.Provider>
  );
};

export default useMuiSidebar;
