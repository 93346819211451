import React from "react";
import { Route, Routes } from "react-router-dom";
import { isElement } from "react-dom/test-utils";
import _ from "lodash";
import navigationRoutes from "./routes";

/**
 * Renders the routes based on the provided navigation key.
 *
 * @param {Object} props - The component props.
 * @param {string} props.navigationKey - The navigation key.
 * @returns {JSX.Element|null} The rendered routes.
 */
const RenderRoutes = ({ navigationKey }) => {
  // Check if the navigation key exists
  const navData = _.get(navigationRoutes, navigationKey);
  if (!navData) {
    throw new Error(
      `The navigation key "${navigationKey}" does not exist in the navigationRoutes object.`
    );
  }

  // Check that either the Element or the children with Elements exist
  const SingleElement = _.get(navData, "Element");
  const children = _.get(navData, "children");

  if (!SingleElement) {
    // If the SingleElement does not exist, children must exist
    if (!children) {
      throw new Error(
        `The navigation key "${navigationKey}" must have either a single Element or children with Elements.`
      );
    }
  } else {
    // If the SingleElement exists, children should not exist
    if (children) {
      console.warn(
        `The navigation key "${navigationKey}" has both a single Element and children with Elements. Only one should exist. The children will be ignored.`
      );
    }

    // If the SingleElement exists, but no path, log a warning
    if (!_.get(navData, "path")) {
      console.warn(
        `The navigation key "${navigationKey}" has a single Element but no path. The path will be set to an empty string.`
      );
    }
  }

  // If the SingleElement exists, render it directly
  const SingleComponent = SingleElement && ComponentElement(SingleElement);
  const singleRoute = SingleComponent && (
    <Route path={""} element={SingleComponent} />
  );

  // If the children exist, render them
  const childRoutes =
    children &&
    children.map((child, index) => {
      const path = _.get(child, "path", "");
      const Element = _.get(child, "Element");
      const Component = ComponentElement(Element);

      if (!Element) {
        console.error(
          `The navigation key "${navigationKey}" has a child route at index ${index} with no Element. The child route will be ignored.`
        );
        return null;
      }

      // Check for Context within the children and wrap the route with it
      const ContextProvider = _.get(child, "Context", null);

      // Check and wrap Component with Context if it exists
      let elementToRender = Component;
      if (ContextProvider) {
        elementToRender = <ContextProvider>{Component}</ContextProvider>;
      }

      return <Route key={index} path={path} element={elementToRender} />;
    });

  return (
    <Routes>
      {singleRoute}
      {childRoutes}
    </Routes>
  );
};

// Convert component references to actual components
const ComponentElement = (Element) => {
  if (isElement(Element)) {
    return Element;
  } else {
    return <Element />;
  }
};

/**
 * Renders the routes for the VAI Explorer application. Data pulled from the navigationRoutes object
 *  stored in /src/routes/routes.js.
 *
 * @returns {JSX.Element} The rendered routes.
 */
const ExplorerRoutes = () => {
  // Use the navigation object to render the routes
  const routeList = Object.entries(navigationRoutes).map(([key, navData]) => {
    // Add the * to the end of the path to allow for nested routes if needed
    const path = _.get(navData, "path", "");
    const navigationPath = pathFixed(path);

    // Check if the context provider exists
    const ContextProvider = _.get(navData, "Context", null);

    // Check and wrap Component with Context if it exists
    const Component = <RenderRoutes navigationKey={key} />;
    let elementToRender = Component;
    if (ContextProvider) {
      elementToRender = <ContextProvider>{Component}</ContextProvider>;
    }

    return <Route key={key} path={navigationPath} element={elementToRender} />;
  });

  return <Routes>{routeList}</Routes>;
};

// Update paths
const pathFixed = (path) =>
  (path && path.endsWith("*") ? path : path + "/*" || "").replace(/\/\//g, "/");

export default ExplorerRoutes;
