import React, { useCallback, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import MenuIcon from "@mui/icons-material/Menu";
import { useState } from "react";
import useAuthentication from "../../hooks/_General/useAuthentication";
import useMuiSidebar from "../../contexts/MuiSidebarContext";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import {
  Drawer,
  Toolbar,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Typography,
  Box,
  AppBar,
  IconButton,
  Avatar,
  Tooltip,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle"; // If using an icon for the avatar
import _ from "lodash";

import navigationRoutes from "../../routes/routes";
import { Link, useLocation } from "react-router-dom";
import CustomChildrenLinks from "./CustomChildrenLinks";
import CustomFolderLinks from "./CustomFolderLinks";
import CustomLink from "./CustomLink";
import { memo } from "react";
import { DoubleArrow, ViewSidebar } from "@mui/icons-material";

const DRAWER_WIDTH = 240;
const MAIN_BG_COLOR = "#111827";
const TEXT_COLOR = "#e3e3e3";
const ACTIVE_TEXT_COLOR = "#FFFFFF";
const ICON_INACTIVE_COLOR = "#b1b1b1";

function MuiSidebar({ children }) {
  // Hooks
  const { showAppbar, showSidebar, toggleSidebar } = useMuiSidebar();

  // States
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      {showAppbar && (
        <AppBar
          position="fixed"
          sx={{
            width: { md: `calc(100% - ${showSidebar ? DRAWER_WIDTH : "0"}px)` },
            ml: { md: `${showSidebar ? DRAWER_WIDTH : "0"}px` },
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Box
        component="nav"
        sx={{
          width: { md: showSidebar ? DRAWER_WIDTH : "0" },
          flexShrink: { md: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              backgroundColor: MAIN_BG_COLOR,
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
            },
          }}
        >
          <CustomDrawer />
        </Drawer>

        <Box
          sx={{
            zIndex: 99999,
            position: "fixed",
            top: "50%",
            left: showSidebar ? -30 : 0,
            display: { xs: "none", sm: "block" },
            transition: "left 0.3s ease",
          }}
        >
          <Tooltip title="Expand Sidebar">
            <IconButton onClick={() => toggleSidebar()}>
              <DoubleArrow color="sidebarCollapse" />
            </IconButton>
          </Tooltip>
        </Box>

        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              backgroundColor: MAIN_BG_COLOR,
              boxSizing: "border-box",
              width: showSidebar ? DRAWER_WIDTH : 0,
              transition: "width 0.3s ease",
              overflowX: "hidden",
            },
          }}
        >
          <CustomDrawer />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { md: `calc(100% - ${showSidebar ? DRAWER_WIDTH : "0"}px)` },
          maxWidth: "100%",
          minHeight: "100vh", // Set height to full viewport
          maxHeight: "100vh", // Set height to full viewport
          overflow: "auto",
        }}
      >
        {showAppbar && <Toolbar />}
        {children}
      </Box>
    </Box>
  );
}

const FSHACLogo = memo(() => (
  <Box
    component="img"
    src="/img/four_seasons_logo.png"
    alt="FSHAC"
    width="80%"
    margin="auto"
    sx={{
      marginLeft: "10px",
    }}
  />
));

const RenderList = memo(
  ({ routeData, linkPath, onClick, isCurrentPage, collapseOpen, isChild }) => (
    <React.Fragment>
      <ListItem disablePadding onClick={onClick}>
        <CustomLink
          routeData={routeData}
          linkPath={linkPath}
          currentPage={isCurrentPage}
          collapseOpen={collapseOpen}
          isChild={isChild}
        />
      </ListItem>
      <Divider />
    </React.Fragment>
  )
);

const ListItems = memo(({ routeName, routeData, open, onChange }) => {
  const location = useLocation();
  const path = useMemo(() => _.get(routeData, "path", ""), [routeData]);
  const children = useMemo(() => _.get(routeData, "children", []), [routeData]);

  // Get folder to path if it exists
  const parentLink = useMemo(
    () => (children.length > 0 ? null : path),
    [children]
  );
  const isCurrentPage = useMemo(
    () => location.pathname.startsWith(path),
    [path, location.pathname]
  );

  return (
    <>
      <RenderList
        routeData={routeData}
        linkPath={parentLink}
        onClick={() => children.length > 0 && onChange(routeName)}
        isCurrentPage={isCurrentPage}
        collapseOpen={open}
      />

      {children.length > 0 && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((childData, index) => {
              const linkPath = `${path}${_.get(childData, "path", "")}`;
              const isCurrentPage = location.pathname === linkPath;
              return (
                <RenderList
                  key={index}
                  routeData={childData}
                  linkPath={linkPath}
                  isCurrentPage={isCurrentPage}
                  isChild={true}
                />
              );
            })}
          </List>
          {open && (
            <Divider
              sx={{
                borderColor: "#2b3854",
                height: "10px",
                margin: "10px",
              }}
            />
          )}
        </Collapse>
      )}
    </>
  );
});

const CustomDrawer = memo(() => {
  const { userData = {} } = useAuthentication();
  console.log("userData: ", userData);
  const muiSidebar = useMuiSidebar();
  const fullname = useMemo(() => _.get(userData, "name", ""), [userData]);
  const userGroups = useMemo(() => _.get(userData, "groups", []), [userData]);

  const location = useLocation();
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleClick = useCallback((route) => {
    setOpenDropdown((prevOpenDropdown) =>
      prevOpenDropdown === route ? null : route
    );
  }, []);

  const toggleSidebar = () => {
    console.log("toggleSidebar: ", muiSidebar);
    muiSidebar.toggleSidebar();
  };

  const adminGroup = _.get(navigationRoutes, "default.adminGroup", "");
  const userIsAdmin = userGroups.includes(adminGroup);

  const filteredRoutes = useMemo(
    () =>
      Object.entries(navigationRoutes).filter(
        ([key, { groups: pathGroups = [] }]) => {
          if (key === "default") return false;

          // If the user is an admin, they have access to all routes
          if (userIsAdmin) return true;

          // If there is no groups key for the route, check for children with groups
          const groupKeyExists = _.get(
            navigationRoutes,
            `${key}.groups`,
            false
          );
          if (!groupKeyExists) {
            const children = _.get(navigationRoutes, `${key}.children`, []);
            return children.some((child) => {
              const childGroups = _.get(child, "groups", []);
              return userGroups.some((group) => childGroups.includes(group));
            });
          } else {
            return pathGroups.some((group) => userGroups.includes(group));
          }
        }
      ),
    [userGroups]
  );

  useEffect(() => {
    const currentPath = location.pathname;
    // Logic to determine which dropdowns should be open based on the current path
    filteredRoutes.forEach(([key, routeData]) => {
      if (routeData.children) {
        // Check if any child path is part of the current path
        const isOpen = routeData.children.some((child) =>
          currentPath.includes(`${routeData.path}${child.path}`)
        );
        if (isOpen) {
          setOpenDropdown(key);
        }
      }
    });
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Toolbar>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "10px",
            // marginRight: "-10px",
          }}
        >
          <FSHACLogo />
          <Tooltip title="Collapse Sidebar">
            <IconButton onClick={toggleSidebar}>
              <DoubleArrow
                color="sidebarCollapse"
                sx={{ transform: "rotate(180deg)" }}
              />
            </IconButton>
          </Tooltip>
        </Box>
      </Toolbar>

      <Divider />

      <List sx={{ color: TEXT_COLOR }}>
        {filteredRoutes.map(([routeName, routeData], index) => (
          <ListItems
            key={index}
            routeName={routeName}
            routeData={routeData}
            open={openDropdown === routeName}
            onChange={handleClick}
          />
        ))}
      </List>

      <Divider
        sx={{
          flexGrow: 1,
        }}
      />

      {/* Add the user icon/avatar at the bottom */}
      <ListItem
        disablePadding
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          "&:hover": {
            backgroundColor: "#1f2937",
          },
        }}
      >
        <Link to="/logout">
          <ListItemButton sx={{ justifyContent: "center" }}>
            <ListItemIcon
              sx={{ minWidth: "auto", justifyContent: "center", mr: 2 }}
            >
              <Avatar
                sx={{ backgroundColor: "#005aa3", width: 30, height: 30 }}
              >
                <AccountCircleIcon />
              </Avatar>
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: "center",
                    fontWeight: "medium",
                    color: "#e3e3e3",
                  }}
                >
                  {fullname}
                </Typography>
              }
              secondary={
                <Typography
                  variant="body2"
                  sx={{ mt: 1, textAlign: "center", color: "#b1b1b1" }}
                >
                  Logout
                </Typography>
              }
              sx={{ mt: 1, textAlign: "center" }}
              primaryTypographyProps={{ noWrap: true }}
            />
          </ListItemButton>
        </Link>
      </ListItem>
    </Box>
  );
});

export default MuiSidebar;
