import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import _ from "lodash";

// Authentication
import Login from "./pages/Authentication/Login";
import Logout from "./pages/Login/Logout";
import MuiSidebar from "./components/MuiSidebar";

// Providers
import { AuthenticationProvider } from "./hooks/_General/useAuthentication";
import ContextProvider from "./contexts/ContextProvider";
import ExplorerRoutes from "./routes/ExplorerRoutes";

/**
 * Main application component where routing is defined.
 * @returns {JSX.Element} The JSX representation of the component's render tree.
 */
function App() {
  return (
    <Router>
      <AuthenticationProvider>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="logout" element={<Logout />} />

          <Route
            path="*"
            element={
              <ContextProvider>
                <MuiSidebar>
                  <ExplorerRoutes />
                </MuiSidebar>
              </ContextProvider>
            }
          />
        </Routes>
      </AuthenticationProvider>
    </Router>
  );
}

export default App;
