import { createContext, useContext, useEffect, useState } from "react";
import * as XLSX from "xlsx";

const useExcelHook = (selectedDate = null) => {
  const [savedData, setSavedData] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const exportRevenueData = (revenueData, dateSelected) => {
    setLoading(true);
    setError(null);

    try {
      let blobData = null;
      if (!savedData[dateSelected]) {
        console.log("useExcel Creating new data...", dateSelected);
        const workbook = XLSX.utils.book_new();

        // Add summary sheet
        const summaryData = [revenueData].map((data) => {
          const {
            MTD_REVENUE,
            YESTERDAY_VAI_REVENUE,
            CMP_REVENUE,
            ...summary
          } = data;
          return summary;
        });

        const summarySheet = XLSX.utils.json_to_sheet(summaryData);
        XLSX.utils.book_append_sheet(workbook, summarySheet, "Summary");

        // Function to create sheets from array data
        const createSheetFromArray = (sheetName, dataArray) => {
          const sheet = XLSX.utils.json_to_sheet(dataArray);
          XLSX.utils.book_append_sheet(workbook, sheet, sheetName);
        };

        // Add other sheets
        if (revenueData.MTD_REVENUE) {
          createSheetFromArray("Revenue.VAI", revenueData.MTD_REVENUE.VAI);
          createSheetFromArray(
            "Revenue.Install",
            revenueData.MTD_REVENUE.BPDB2["HVAC INSTALL"]
          );
          createSheetFromArray(
            "Revenue.Generator",
            revenueData.MTD_REVENUE.BPDB2["GENERATOR"]
          );
        }
        if (revenueData.YESTERDAY_VAI_REVENUE) {
          createSheetFromArray(
            "Yesterday Revenue.VAI",
            revenueData.YESTERDAY_VAI_REVENUE.VAI
          );
          createSheetFromArray(
            "Yesterday Revenue.Install",
            revenueData.YESTERDAY_VAI_REVENUE.BPDB2["HVAC INSTALL"]
          );
          createSheetFromArray(
            "Yesterday Revenue.Generator",
            revenueData.YESTERDAY_VAI_REVENUE.BPDB2["GENERATOR"]
          );
        }
        if (revenueData.CMP_REVENUE) {
          createSheetFromArray("CMP Revenue.VAI", revenueData.CMP_REVENUE);
        }

        // Write workbook to binary string
        const wbout = XLSX.write(workbook, { type: "binary" });

        // Create a Blob for download
        const blob = new Blob([s2ab(wbout)], {
          type: "application/octet-stream",
        });

        // Save the blob data to the state with the date as the key
        setSavedData((prev) => ({ ...prev, [dateSelected]: blob }));
        blobData = blob;
      } else {
        console.log("useExcel Using saved data...", dateSelected);
        blobData = savedData[dateSelected];
      }

      const url = URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = `ungrouped_revenue_flash_${dateSelected}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (err) {
      console.error("Error exporting revenue data:", err);
      setError(err);
    }

    setLoading(false);
  };

  // Utility function to convert to octet stream
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  };

  return { loading, error, exportRevenueData };
};

const ExcelContext = createContext(null);

export const ExcelProvider = ({ children }) => {
  const excel = useExcelHook();
  return (
    <ExcelContext.Provider value={excel}>{children}</ExcelContext.Provider>
  );
};

export const useExcel = () => useContext(ExcelContext);
