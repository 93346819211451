function Logout() {
  // Remove all local storage items
  localStorage.clear();

  // Redirect to login page
  window.location.href = "/login";

  return null;
}

export default Logout;
