// ContextProvider.js
import React from "react";

import { ExcelProvider } from "../hooks/_General/useExcel";
import { MuiSidebarProvider } from "./MuiSidebarContext";
import { BadgeNotificationProvider } from "../hooks/_General/useBadgeNotification";

const ContextProvider = ({ children }) => {
  return (
    <MuiSidebarProvider>
      <ExcelProvider>
        <BadgeNotificationProvider>{children}</BadgeNotificationProvider>
      </ExcelProvider>
    </MuiSidebarProvider>
  );
};

export default ContextProvider;
