import { createContext, useContext, useState } from "react";

const useBadgeNotificationHook = () => {
  const [data, setData] = useState();

  const setBadges = (key, value) => {
    setData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  return { setBadges, data };
};

const BadgeNotificationContext = createContext(null);

export const BadgeNotificationProvider = ({ children }) => {
  const badgeNotification = useBadgeNotificationHook();
  return (
    <BadgeNotificationContext.Provider value={badgeNotification}>
      {children}
    </BadgeNotificationContext.Provider>
  );
};

export const useBadgeNotification = () => useContext(BadgeNotificationContext);
