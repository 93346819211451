import { createContext, useContext, useEffect, useState } from "react";
import useAuthentication from "../_General/useAuthentication";
import { parseNumberFromString } from "../../utils/util";

const useCallCreationHook = () => {
  const { query } = useAuthentication();

  // Search Variables
  const [srchData, setSrchData] = useState(null);
  const [srchLoading, setSrchLoading] = useState(false);
  const [srchError, setSrchError] = useState(true);

  // Appointment Varaiables
  const [apptData, setApptData] = useState(null);
  const [apptLoading, setApptLoading] = useState(false);
  const [apptError, setApptError] = useState(null);

  // Technicians Variables
  const [techData, setTechData] = useState([]);
  const [techLoading, setTechLoading] = useState(false);
  const [techError, setTechError] = useState(null);

  const fetchData = async ({
    customer_number,
    email,
    address,
    name,
    city,
    phone,
  } = {}) => {
    // if (loading || error) {
    //   return;
    // }
    setSrchLoading(true);
    try {
      setSrchError(null);
      // Build the query string
      const queryParams = new URLSearchParams();
      if (customer_number)
        queryParams.append("customer_number", customer_number);
      if (email) queryParams.append("email", email);
      if (address) queryParams.append("address", address);
      if (name) queryParams.append("name", name);
      if (city) queryParams.append("city", city);
      if (phone) queryParams.append("phone", phone);

      const queryString = queryParams.toString();
      const data = await query.get(
        `/call-creation/search-customers?${queryString}`
      );

      setSrchData(data);
    } catch (error) {
      setSrchError(error);
      console.log(error);
    } finally {
      setSrchLoading(false);
    }
  };

  const fetchTechnicians = async () => {
    setTechLoading(true);
    try {
      setTechError(null);
      const data = await query.get("/call-creation/search-technicians");
      setTechData(data);
    } catch (error) {
      setTechError(error);
      console.log(error);
    } finally {
      setTechLoading(false);
    }
  };

  const postData = async (queryData) => {
    setApptLoading(true);
    try {
      setApptError(null);
      const data = await query.post(
        `/call-creation/insert-appointment`,
        queryData
      );
      setApptData(data);
      // console.log(data.data.message);
      return null; // If nothing is returned, It was successful
    } catch (error) {
      setApptError(error);
      // console.log(error.response.data.errors);
      return error;
    } finally {
      setApptLoading(false);
    }
  };

  // Fetch initial data or use cache
  // useEffect(() => {
  //   fetchData();
  // }, []);

  return {
    srchData,
    srchLoading,
    srchError,
    fetchData,
    techData,
    techLoading,
    techError,
    fetchTechnicians,
    apptData,
    apptLoading,
    apptError,
    postData,
  };
};

const CallCreationContext = createContext();

const CallCreationProvider = ({ children }) => {
  const CallCreation = useCallCreationHook();

  return (
    <CallCreationContext.Provider value={CallCreation}>
      {children}
    </CallCreationContext.Provider>
  );
};

const useCallCreation = () => {
  const context = useContext(CallCreationContext);
  if (!context) {
    throw new Error(
      "useCallCreation must be used within a CallCreationProvider"
    );
  }
  return context;
};

export { CallCreationProvider, useCallCreation };
export default useCallCreation;
