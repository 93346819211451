import React, { useEffect, useState, useMemo } from "react";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  Chip,
  Grid,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  IconButton,
  InputAdornment,
  Autocomplete,
  Snackbar,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import _ from "lodash";
import useCallCreation from "../../../../hooks/Admin/useCallCreation";

const AppointmentForm = ({ open, onCancel, onSubmit, customerData }) => {
  const callCreate = useCallCreation();
  const initialFormData = {
    is_new_customer: true,
    company_number: "1",
    priority_code: "",
    home_owner: "",
    address_1: "",
    city: "",
    state: "",
    zip: "",
    cell_phone: "",
    alt_phone: "",
    email: "",
    brand: "",
    store_number: "",
    appointment_date: dayjs().format("YYYYMMDD"),
    appointment_time: "",
    // appointment_date_time: "",
    lg_number: "",
    lead_number: "",
    equipment_type: "",
    location: "",
    ladder: "",
    notes: "",
    crt_date: dayjs().format("YYYYMMDD"),
    crt_time: dayjs().format("HHmmss"),
    customer: "",
    customer_class: "",
    commercial_residential: "",
    campaign_code: "DEXCOMM",
    assigned_technician: "",
    did_we_install: "",
    age_of_unit: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  // Variables needed for State Dropdown Field
  const states = [
    "AL",
    "AK",
    "AZ",
    "AR",
    "CA",
    "CO",
    "CT",
    "DE",
    "FL",
    "GA",
    "HI",
    "ID",
    "IN",
    "IA",
    "KS",
    "KY",
    "LA",
    "ME",
    "MD",
    "MA",
    "MI",
    "MN",
    "MS",
    "MO",
    "MT",
    "NE",
    "NV",
    "NH",
    "NJ",
    "NM",
    "NY",
    "NC",
    "ND",
    "OH",
    "OK",
    "OR",
    "PA",
    "RI",
    "SC",
    "SD",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
    "WI",
    "WY",
  ];
  const sortedStates = ["IL", ...states.sort()];

  useEffect(() => {
    if (customerData) {
      // If customerData exists (not null),
      // fill Customer data and make fields not editable.
      setFormData((prevFormData) => ({
        ...prevFormData,
        is_new_customer: false,
        company_number: customerData.company_number || "",
        home_owner: customerData.customer_name || "",
        cell_phone: customerData.customer_phone || "",
        email: customerData.customer_email || "",
        address_1: customerData.customer_address || "",
        city: customerData.customer_city || "",
        state: customerData.customer_state || "",
        zip: customerData.customer_zip || "",
        alt_phone: customerData.customer_altphone || "",
        customer: customerData.customer_number || "",
        customer_class: customerData.customer_class || "",
      }));
    }
  }, [customerData]);

  const [successMessage, setSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorsList, setErrorsList] = useState({});
  const [errors, setErrors] = useState({});
  const [unitOptions, setUnitOptions] = useState([]);

  // console.log("customerData: ", customerData);
  // console.log("Data: ", formData);
  //
  // Check formData to be filled & formatted
  //
  const validate = () => {
    const newErrors = {};

    // Validation Rules
    if (!formData.company_number) newErrors.company_number = "Required";
    if (!formData.priority_code) newErrors.priority_code = "Required";
    if (!formData.home_owner) newErrors.home_owner = "Required";
    if (!formData.email) newErrors.email = "Required";
    if (!formData.cell_phone) newErrors.cell_phone = "Required";
    if (!formData.address_1) newErrors.address_1 = "Required";
    if (!formData.city) newErrors.city = "Required";
    if (!formData.state) newErrors.state = "Required";
    if (!formData.zip) {
      newErrors.zip = "Required";
    } else if (!/^\d{5}(-\d{4})?$/.test(formData.zip)) {
      newErrors.zip = "Invalid zip code format";
    }
    if (!formData.appointment_date) newErrors.appointment_date = "Required";
    if (!formData.appointment_time) newErrors.appointment_time = "Required";
    if (!formData.equipment_type) newErrors.equipment_type = "Required";
    if (!formData.location) newErrors.location = "Required";
    if (!formData.location) newErrors.location = "Required";
    if (!formData.ladder) newErrors.ladder = "Required";
    if (!formData.notes) newErrors.notes = "Required";

    // Specific Requirements if Existing Customer
    if (formData.is_new_customer == false) {
      if (!formData.customer) newErrors.customer = "Required";
    }

    // Specific Requirements for SC or CC
    if (formData.priority_code == "SC" || formData.priority_code == "CC") {
      if (!formData.did_we_install) newErrors.did_we_install = "Required";
      if (!formData.age_of_unit) newErrors.age_of_unit = "Required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const addressAbbrs = {
    Street: "St",
    Court: "Ct",
    Drive: "Dr",
    Road: "Rd",
    Circle: "Cir",
    Trail: "Trl",
    Boulevard: "Blvd",
    Place: "PL",
    Avenue: "Ave",
    Lane: "Ln",
    North: "N",
    South: "S",
    East: "E",
    West: "W",
  };

  const validateAddress = (address) => {
    let abbreviatedAddress = address;
    for (const [key, value] of Object.entries(addressAbbrs)) {
      const regex = new RegExp(`\\b${key}\\b`, "gi");
      abbreviatedAddress = abbreviatedAddress.replace(regex, value);
    }
    return abbreviatedAddress;
  };

  //
  // Change Equipment Field based on Priority Field
  //
  const handlePriorityChange = (e) => {
    const value = e.target.value;
    setFormData({
      ...formData,
      priority_code: value,
      equipment_type: "", // Reset estimate type when priority changes
    });

    switch (value) {
      case "P":
      case "RD":
        setUnitOptions(["PLUMBING"]);
        break;
      case "GS":
        setUnitOptions(["GENERATOR"]);
        break;
      case "EE":
        setUnitOptions(["ELECTRICAL"]);
        break;
      case "SC":
      case "CC":
        setUnitOptions(["FURNACE", "AC", "BOILER"]);
        setFormData({
          ...formData,
          priority_code: value,
          assigned_technician: "", // Reset Tech to be Blank
        });
        break;
      default:
        setUnitOptions([]);
        break;
    }
  };

  const handleChange = async (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDateChange = async (date) => {
    setFormData({
      ...formData,
      appointment_date: dayjs(date).format("YYYYMMDD"),
    });
  };

  const handleClose = () => {
    // Call the onClose function
    setErrorMessage(null);
    setErrors({});
    setFormData(initialFormData);
    onCancel();
  };

  const handleSubmit = async () => {
    // Check Front-end Validation
    if (!validate()) {
      setErrorMessage("Please enter all required fields.");
      return;
    }

    // Valid Address Field (Abbreviate text)
    const abbreviatedAddress = validateAddress(formData.address_1);

    const appointmentData = {
      ...formData,
      address_1: abbreviatedAddress,
      notes: `${formData.did_we_install ? `${formData.did_we_install}` : ""} ${
        formData.age_of_unit ? `${formData.age_of_unit}` : ""
      } ${formData.notes}`,
      crt_date: dayjs().format("YYYYMMDD"),
      crt_time: dayjs().format("HHmmss"),
    };
    setFormData(appointmentData);

    const result = await callCreate.postData(appointmentData);

    if (result != null) {
      console.log("ERROR: ", result);
      setErrorMessage(result.response.data.message || "An error occurred.");
      setErrorsList(result.response.data.errors || "");
    } else {
      setErrorMessage(null);
      setErrors({});
      setFormData(initialFormData);
      setSuccessMessage(true);

      onSubmit();
    }
  };

  //
  // Fetch Technicicans and Store the result
  //
  useEffect(() => {
    callCreate.fetchTechnicians();
  }, []);
  // console.log("Tech: ", callCreate.techData);

  return (
    <>
      <style>
        {`
          .no-highlight {
            user-select: none;
            pointer-events: none;
          }
        `}
      </style>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        style={{ userSelect: "none" }}
      >
        <DialogTitle>Book Appointment</DialogTitle>
        <DialogContent>
          {errorMessage && (
            <Alert severity="error">
              {errorMessage}{" "}
              {Object.keys(errorsList).map((field) =>
                errorsList[field].map((message, index) => (
                  <div key={index}>{message}</div>
                ))
              )}
            </Alert>
          )}
          <Grid
            container
            spacing={2}
            sx={{ py: 1 }}
            style={{ userSelect: "none" }}
          >
            {/* <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="Company Number"
                name="company_number"
                value={formData.company_number}
                disabled={!!customerData?.company_number}
                onChange={handleChange}
                type="number"
                required
                inputProps={{ maxLength: 3 }}
                error={!!errors.company_number}
                helperText={errors.company_number}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                size="small"
                label="Priority Code"
                name="priority_code"
                value={formData.priority_code}
                onChange={handlePriorityChange}
                inputProps={{ maxLength: 2 }}
                required
                error
                helperText={errors.priority_code}
              >
                <MenuItem value="CC">CC - Clean and Check</MenuItem>
                <MenuItem value="GS">GS - Generator Service Call</MenuItem>
                <MenuItem value="SC">SC - Service Call</MenuItem>
                <MenuItem value="P">P - Plumbing</MenuItem>
                <MenuItem value="RD">RD - Rodding</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="Home Owner"
                name="home_owner"
                value={formData.home_owner}
                disabled={!!customerData?.customer_name}
                onChange={handleChange}
                inputProps={{
                  maxLength: 30,
                  className: !!customerData?.customer_name
                    ? "no-highlight"
                    : "",
                }}
                required
                error
                helperText={errors.home_owner}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="Email"
                name="email"
                value={formData.email}
                disabled={
                  customerData?.customer_email !== "N/A" &&
                  !!customerData?.customer_email
                }
                onChange={handleChange}
                inputProps={{
                  maxLength: 50,
                  className:
                    customerData?.customer_email !== "N/A" &&
                    !!customerData?.customer_email
                      ? "no-highlight"
                      : "",
                }}
                required
                error
                helperText={errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="Cell Phone"
                name="cell_phone"
                value={formData.cell_phone}
                disabled={
                  customerData?.customer_phone !== "N/A" &&
                  !!customerData?.customer_phone
                }
                onChange={handleChange}
                inputProps={{
                  maxLength: 10,
                  className:
                    customerData?.customer_phone !== "N/A" &&
                    !!customerData?.customer_phone
                      ? "no-highlight"
                      : "",
                }}
                onInput={(e) => {
                  e.target.value = e.target.value
                    .replace(/[^0-9]/g, "")
                    .slice(0, 10);
                }}
                required
                error
                helperText={errors.cell_phone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="Alt Phone"
                name="alt_phone"
                value={formData.alt_phone}
                inputProps={{
                  maxLength: 10,
                  className:
                    customerData?.customer_altphone !== "N/A" &&
                    !!customerData?.customer_altphone
                      ? "no-highlight"
                      : "",
                }}
                onInput={(e) => {
                  e.target.value = e.target.value
                    .replace(/[^0-9]/g, "")
                    .slice(0, 10);
                }}
                disabled={
                  customerData?.customer_altphone !== "N/A" &&
                  !!customerData?.customer_altphone
                }
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="Address 1"
                name="address_1"
                value={formData.address_1}
                disabled={!!customerData?.customer_address}
                onChange={handleChange}
                inputProps={{
                  maxLength: 30,
                  className: !!customerData?.customer_address
                    ? "no-highlight"
                    : "",
                }}
                required
                error
                helperText={errors.address_1}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="City"
                name="city"
                value={formData.city}
                disabled={!!customerData?.customer_city}
                onChange={handleChange}
                inputProps={{
                  maxLength: 30,
                  className: !!customerData?.customer_city
                    ? "no-highlight"
                    : "",
                }}
                required
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^a-zA-Z\s]/g, "");
                }}
                error
                helperText={errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                size="small"
                label="State"
                name="state"
                value={formData.state}
                disabled={!!customerData?.customer_state}
                onChange={handleChange}
                inputProps={{
                  maxLength: 2,
                  className: !!customerData?.customer_state
                    ? "no-highlight"
                    : "",
                }}
                required
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^a-zA-Z]/g, "");
                }}
                error
                helperText={errors.state}
              >
                {sortedStates.map((state) => (
                  <MenuItem key={state} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="Zip"
                name="zip"
                value={formData.zip}
                disabled={!!customerData?.customer_zip}
                onChange={handleChange}
                inputProps={{
                  maxLength: 10,
                  className: !!customerData?.customer_zip ? "no-highlight" : "",
                }}
                required
                onInput={(e) => {
                  let { value } = e.target;

                  // Allow only valid zip code formats during input
                  if (/^\d{0,5}(-\d{0,4})?$/.test(value)) {
                    e.target.value = value;
                  } else {
                    e.target.value = formData.zip; // Revert to previous valid value
                  }
                }}
                error
                helperText={errors.zip}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  size="small"
                  label="Appointment Date"
                  value={dayjs(formData.appointment_date, "YYYYMMDD")}
                  onChange={handleDateChange}
                  disablePast
                  maxDate={dayjs().add(30, "day")}
                  slotProps={{
                    textField: {
                      size: "small",
                      error: true,
                      helperText: errors.appointment_date,
                      required: true,
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      required
                      error
                      helperText={errors.appointment_date}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                size="small"
                label="Appointment Time"
                name="appointment_time"
                value={formData.appointment_time}
                onChange={handleChange}
                required
                error
                helperText={errors.appointment_time}
              >
                <MenuItem value="080000">8am-12pm</MenuItem>
                <MenuItem value="100000">10am-2pm</MenuItem>
                <MenuItem value="120000">12pm-4pm</MenuItem>
                <MenuItem value="140000">2pm-6pm</MenuItem>
              </TextField>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="Brand"
                name="brand"
                value={formData.brand}
                onChange={handleChange}
                inputProps={{ maxLength: 50 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="Store Number"
                name="store_number"
                value={formData.store_number}
                onChange={handleChange}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="LG Number"
                name="lg_number"
                value={formData.lg_number}
                onChange={handleChange}
                inputProps={{ maxLength: 10 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="Lead Number"
                name="lead_number"
                value={formData.lead_number}
                onChange={handleChange}
                inputProps={{ maxLength: 15 }}
              />
            </Grid> */}
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                size="small"
                label="Equipment Type"
                name="equipment_type"
                value={formData.equipment_type}
                required
                error
                helperText={errors.equipment_type}
                onChange={handleChange}
                inputProps={{ maxLength: 30 }}
              >
                {unitOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            {(formData.priority_code === "SC" ||
              formData.priority_code === "CC") && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    select
                    fullWidth
                    size="small"
                    label="Did We Install?"
                    name="did_we_install"
                    value={formData.did_we_install}
                    onChange={handleChange}
                    inputProps={{ maxLength: 1 }}
                    required
                    error
                    helperText={errors.did_we_install}
                  >
                    <MenuItem value="Y">Yes</MenuItem>
                    <MenuItem value="N">No</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Age of Unit"
                    name="age_of_unit"
                    value={formData.age_of_unit}
                    onChange={handleChange}
                    inputProps={{ maxLength: 2 }}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9]/g, "")
                        .slice(0, 2);
                    }}
                    required
                    error
                    helperText={errors.age_of_unit}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                size="small"
                label="Location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                inputProps={{ maxLength: 30 }}
                required
                error
                helperText={errors.location}
              >
                <MenuItem value="Basement">Basement</MenuItem>
                <MenuItem value="Closet">Closet</MenuItem>
                <MenuItem value="Attic">Attic</MenuItem>
                <MenuItem value="Crawl Space">Crawl Space</MenuItem>
                <MenuItem value="Roof">Roof</MenuItem>
                <MenuItem value="Ground Level">Ground Level</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                size="small"
                label="Ladder Required?"
                name="ladder"
                value={formData.ladder}
                onChange={handleChange}
                inputProps={{ maxLength: 1 }}
                required
                error
                helperText={errors.ladder}
              >
                <MenuItem value="Y">Yes</MenuItem>
                <MenuItem value="N">No</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                size="small"
                label="Notes"
                name="notes"
                value={formData.notes}
                onChange={handleChange}
                multiline
                rows={4}
                variant="outlined"
                inputProps={{ maxLength: 176 }}
                helperText={`${formData.notes.length}/176 ${
                  errors.notes || ""
                }`}
                required
                error
              />
            </Grid>
            {formData.is_new_customer === false && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Customer"
                    name="customer"
                    value={formData.customer}
                    disabled={!!customerData?.customer_number}
                    onChange={handleChange}
                    inputProps={{
                      maxLength: 7,
                      className: !!customerData?.customer_number
                        ? "no-highlight"
                        : "",
                    }}
                    type="number"
                    required
                    error
                    helperText={errors.customer}
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                size="small"
                label="Customer Class"
                name="customer_class"
                value={formData.customer_class}
                disabled={!!customerData?.customer_class}
                onChange={handleChange}
                inputProps={{
                  maxLength: 2,
                  className: !!customerData?.customer_class
                    ? "no-highlight"
                    : "",
                }}
              >
                <MenuItem value="AD">AD</MenuItem>
                <MenuItem value="FS">FS</MenuItem>
                <MenuItem value="ME">ME</MenuItem>
                <MenuItem value="NW">NW</MenuItem>
                <MenuItem value="TM">TM</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                select
                fullWidth
                size="small"
                label="Commercial/Residential"
                name="commercial_residential"
                value={formData.commercial_residential}
                onChange={handleChange}
                inputProps={{ maxLength: 3 }}
              >
                <MenuItem value="COM">Commercial</MenuItem>
                <MenuItem value="RES">Residential</MenuItem>
              </TextField>
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                size="small"
                label="Campaign Code"
                name="campaign_code"
                value={formData.campaign_code}
                onChange={handleChange}
                inputProps={{ maxLength: 10 }}
              />
            </Grid> */}
            {formData.priority_code !== "SC" &&
              formData.priority_code !== "CC" && (
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    options={_.get(callCreate, "techData.data", [])}
                    getOptionLabel={(option) => option.technician_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Assigned Technician"
                        name="assigned_technician"
                        size="small"
                        error={!!errors.assigned_technician}
                        helperText={errors.assigned_technician}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setFormData({
                        ...formData,
                        assigned_technician: newValue?.technician_number || "",
                      });
                    }}
                  />
                </Grid>
              )}
          </Grid>
          {errorMessage && (
            <Alert severity="error">
              {errorMessage}{" "}
              {Object.keys(errorsList).map((field) =>
                errorsList[field].map((message, index) => (
                  <div key={index}>{message}</div>
                ))
              )}
            </Alert>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          {callCreate.apptLoading ? (
            <CircularProgress />
          ) : (
            <Button
              onClick={handleSubmit}
              color="primary"
              // disabled={disableSaveButton}
            >
              Submit
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <Snackbar
        open={successMessage}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={10000}
        onClose={() => setSuccessMessage(false)}
        message="Appointment successfully scheduled"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setSuccessMessage(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </>
  );
};

export default AppointmentForm;
