import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import {
  Badge,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import _ from "lodash";
import { memo } from "react";
import { useBadgeNotification } from "../../hooks/_General/useBadgeNotification";

const ACTIVE_TEXT_COLOR = "#FFFFFF";
const ICON_INACTIVE_COLOR = "#b1b1b1";

const CustomLink = ({
  routeData,
  linkPath = "",
  currentPage = false,
  collapseOpen = false,
  isChild = false,
}) => {
  const badgeNotification = useBadgeNotification();

  // JSX Elements
  const FolderIcon = _.get(routeData, "Icon");

  // Objects
  const children = _.get(routeData, "children", []);
  const text = _.get(routeData, "text");

  // Manage the JSX elements here
  const ExpandIcon = useMemo(
    () => (collapseOpen ? ExpandMore : KeyboardArrowRight),
    [collapseOpen]
  );

  const styleColor = useMemo(
    () => (currentPage ? ACTIVE_TEXT_COLOR : ICON_INACTIVE_COLOR),
    [currentPage]
  );

  const iconStyles = {
    flexShrink: 0,
    color: styleColor,
  };
  const iconListStyles = {
    ...(isChild && {
      paddingLeft: "20px",
    }),
  };

  return (
    <Link
      to={linkPath}
      style={{
        textDecoration: "none",
        color: "inherit",
        display: "flex",
        width: "100%",
      }}
    >
      <ListItemButton
        sx={{
          "&:hover": {
            backgroundColor: "#374151",
          },
        }}
      >
        {FolderIcon && (
          <ListItemIcon sx={iconListStyles}>
            <FolderIcon height={24} width={24} style={iconStyles} />
          </ListItemIcon>
        )}
        <ListItemText
          primary={
            <Typography
              variant="body2"
              sx={{
                mr: 2,
                fontWeight: currentPage ? 600 : 400, // Bold for active, regular for inactive
                color: styleColor, // Color changes based on active state
                transition: "color 0.3s ease", // Smooth transition for color change
                textWrap: "noWrap",
              }}
            >
              {text}
            </Typography>
          }
          primaryTypographyProps={{ noWrap: true }}
        />
        {_.get(routeData, "getBadgeContent") && (
          <ListItemSecondaryAction>
            <Badge
              badgeContent={_.get(
                badgeNotification,
                `data.${routeData.getBadgeContent}`,
                0
              )}
              color="primary"
            />
          </ListItemSecondaryAction>
        )}
        {children.length > 0 && (
          <ExpandIcon sx={{ color: ICON_INACTIVE_COLOR }} />
        )}
      </ListItemButton>
    </Link>
  );
};

export default memo(CustomLink);
